import React from 'react';
import Header from '../../components/header';
import FeatBrands from '../../components/feat-brands';
import AppFeatures from '../../components/app-features';
import GetStarted from '../../components/get-started';
import Performance from '../../components/performance';
import FooCta from '../../components/foo-cta';
import Footer from '../../components/footer';
import Intercom from '../../components/intercom';
// import '../../components/intercom/index'



const LandingPage = () => {
    return(
        <>
            <Header />
            <FeatBrands />
            <AppFeatures />
            <Performance />
            <GetStarted />
            <FooCta />
            <Footer />
            <Intercom />
        </>
    );
}

export default LandingPage;