import React from 'react';
import './style.scss';
import app_store from '../../images/app_store.png';
import google_play from '../../images/google_play.png';
import CtaMockup from '../../images/phone_mockup1.png'

const FooCta = ()=> {
    return(
        <section className="Foo-Cta">
            <div className="container">
                <div className="cta-text">
                    <h2>Get a fully managed <span>portfolio</span> </h2>
                    <p className='Lead'>Start investing like the top 1% today. Create a free account in 3 minutes!</p>
                    <div className='Cta'>
                        <a href='https://apps.apple.com/us/app/xantos-labs/id1571651655'>
                            <img src={app_store} alt='app_store' />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.xantoslabs'>
                            <img src={google_play} alt='google_play' />
                        </a>
                    </div>
                </div>
                <div className="cta-img desktop">
                    <img width='100%' src={CtaMockup} alt="app mockup" />
                </div>
            </div>
        </section>
    );
}


export default FooCta;