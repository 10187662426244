import React from 'react';
import './style.scss';
import app_store from '../../images/app_store.png';
import google_play from '../../images/google_play.png';
import Linkedin from '../../images/linkedin.svg';
import Twitter from '../../images/twitter.svg';
import Instagram from '../../images/instagram.svg';
import XantosLogo from '../../images/xantos-labs-logo-white.svg'

const Footer = () => {
    return(
        <div className="Footer">
            {/* <div className="sitemap">
                <div className="container nav">
                    <ul>
                        <li class='current'><a href='#index'>Home</a></li>
                        <li><a href='#index'>Features</a></li>
                        <li><a href='#index'>About Us</a></li>
                        <li><a href='#index'>Help</a></li>
                    </ul>
                </div>
            </div> */}
            <div className="container">
                <div className='row'>
                    <div className='logo'>
                        <ul>
                            <li>
                                <img src={XantosLogo} alt="xantos logo" />
                            </li>
                            <li><a href='#index'>Austin, TX.</a></li>
                            <li><a href="mailto:support@xantoslabs.com">support@xantos.app</a></li>
                            <li>
                                <a href="https://www.instagram.com/myxantos/" target="_blank" rel="noopener noreferrer">
                                    <img src={Instagram} alt="instagram" />
                                </a>
                                <a href="http://twitter.com/myxantos" target="_blank" rel="noopener noreferrer">
                                    <img src={Twitter} alt="twitter" />
                                </a>
                                <a href="http://www.linkedin.com/company/xantos-labs/" target="_blank" rel="noopener noreferrer">
                                    <img src={Linkedin} alt="linkedin" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='sitemap'>
                        <h3>Product</h3>
                        <ul>
                            <li><a href='#home'>Home</a></li>
                            <li><a href='#features'>Features</a></li>
                            <li><a href='#getStarted'>Get Started</a></li>
                            {/* <li><a href='#index'>Help</a></li> */}
                        </ul>
                        <h3>Company</h3>
                        <ul>
                            <li><a href='https://xantoslabs.com/about-us'>About Us</a></li>
                            <li><a href='https://xantoslabs.com/faq'>FAQs</a></li>
                            <li><a href='https://xantoslabs.com/library'>Learn</a></li>
                            <li><a href='https://xantoslabs.com/legal'>Legal</a></li>
                        </ul>
                    </div>
                    <div className='cta'>
                        <a href='https://apps.apple.com/us/app/xantos-labs/id1571651655'>
                            <img src={app_store} alt='app_store' />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.xantoslabs'>
                            <img src={google_play} alt='google_play' />
                        </a>
                    </div>
                </div>
                <div className="copyright">
                    <p>
                        Xantos Labs ("Xantos") is an SEC-registered investment adviser. 
                        Research Xantos Labs with FINRA’s BrokerCheck. <br /><br />
                        The materials on this website are for illustration and discussion 
                        purposes only and do not constitute an offer to, or solicitation of, 
                        any potential clients or investors for the provision by Xantos Labs of 
                        investment management, advisory, or any other related services. 
                        An offering may be made only by the delivery of a confidential offering memorandum to appropriate investors. 
                        Past performance is not indicative of future results. <br /><br />
                        Brokerage services are provided by Alpaca Securities LLC ("Alpaca"), member FINRA/SIPC, a wholly-owned subsidiary of 
                        AlpacaDB, Inc. This is not an offer, solicitation of an offer, or advice to buy or sell securities, or open a brokerage 
                        account in any jurisdiction where Alpaca is not registered (Alpaca is registered only in the United States).<br /><br />
                        Links from this website to third-party websites do not imply any endorsement 
                        by the third party of this website or of the link; nor do they imply any endorsement 
                        by this firm of the third-party website or of the link. Copyright © 2022 Xantos Labs LLC. 
                        All rights reserved. 
                    </p>
                    <p className="foo-p">
                        Copyright &copy; 
                        {/* {Date().getFullYear()} */} 2022
                        Xantos Labs | 
                        <a href="https://xantoslabs.com/privacy"> See Our Privacy Policy</a>  | 
                        <a href="https://xantoslabs.com/terms"> Read Our Terms of Use</a> 
                    </p>
                </div>
            </div>
        </div>
    );
}


export default Footer;