import React from 'react';
import landing_page from '../../images/phone_mockup.png';
import app_store from '../../images/app_store.png';
import google_play from '../../images/google_play.png';

const Hero = () => {
    return(
        <div className='Hero'>
            <div className="container">
                <div className='Hero-Msg'>
                    {/* <h2>Jump start your <span>investment portfolio</span>  </h2> */}
                    {/* <p className='Lead'>Xantos is the easiest way to invest in US stocks like the top 1%.</p> */}
                    <h2>The Easiest way to <span>invest in US stocks from Africa</span>  </h2>
                    <p className='Lead'>Invest like the world's billionaires from anywhere in Africa.</p>
                    <div className='Hero-Img mobile'>
                        <img className='Img' width='100%' src={landing_page} alt='app mockup' />
                        <a href="https://www.producthunt.com/posts/xantos-labs?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-xantos-labs" target="_blank" rel="noreferrer">
                            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=317534&theme=light" alt="Xantos App - Active investment platform for anyone in the world | Product Hunt" width="250" height="54" />
                        </a>
                    </div>
                    <div className='Cta'>
                        <a href='https://apps.apple.com/us/app/xantos-labs/id1571651655'>
                            <img src={app_store} alt='app_store' />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.xantoslabs'>
                            <img src={google_play} alt='google_play' />
                        </a>
                    </div>
                </div>
                <div className='Hero-Img desktop'>
                    <img className='Img' width='100%' src={landing_page} alt='app mockup' />
                    <a href="https://www.producthunt.com/posts/xantos-labs?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-xantos-labs" target="_blank" rel="noreferrer">
                        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=317534&theme=light" alt="Xantos App - Active investment platform for anyone in the world | Product Hunt" width="250" height="54" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Hero;