import React from 'react';
import './style.scss';
import Marketwatch from '../../images/marketwatch.svg';
import Cbs from '../../images/cbs.svg';
import Nbc from '../../images/nbc.svg';
import Usa from '../../images/usa.svg';
import Fox from '../../images/Fox.svg';
import Benzinga from '../../images/benzinga.svg';

const FeatBrands = () => {
    return(
        <section className='Feat-Brands'>
            <div className="container">
                <div className="brand-logos">
                    <div className="beside">
                        <h3>As Featured in</h3>
                    </div>
                    <div className="logos">
                        <img className="benzinga filtered" src={Benzinga} alt="benzinga logo" />
                        <img className="img filtered" src={Nbc} alt="nbc logo" />
                        <img className="img filtered" src={Cbs} alt="cbc logo" />
                        <img className="img filtered" src={Usa} alt="usa logo" />
                        <img className="img filtered" src={Fox} alt="fox logo" />
                        <img className="marketwatch filtered" src={Marketwatch} alt="marketwatch logo" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeatBrands;