import React from 'react';
import './header.scss';
import Nav from './Nav';
import Hero from './Hero';

const Header = () => {
    return(
        <div className='Header' id='home'>
            <Nav />
            <Hero />
        </div>
    );
}


export default Header;