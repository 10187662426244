import React from 'react';
import './style.scss';
// import Icon1 from '../../images/icon1.svg';
// import Icon2 from '../../images/icon2.svg';
// import Icon3 from '../../images/icon3.svg';
import Grow from '../../images/growing.png';
import Shield from '../../images/shield.png';
import Profit from '../../images/profits.png'

const AppFeatures = () => {
    return(
        <section className='App-Features' id='features'>
            <div className="container">
                <div className='title'>
                    <h2>Start building your wealth like the top 1%</h2>
                    <p>Build your investment portfolio with Xantos, get rid of the usual hassle and confusion</p>
                </div>
                <div className='row'>
                    <div className='feat'>
                        <img src={Grow} alt="feat-img" width="100%" />
                        <h3>Easiest way to grow your money</h3>
                        <p>
                            Making money is tough, investing it shouldn't be. 
                            Eliminate the confusion and risks that comes with 
                            setting up and managing your own trading account — with 
                            ZERO trading fees!
                        </p>
                    </div>
                    <div className="feat">
                        <img src={Profit} alt="feat-img" width="100%" />
                        <h3>Enjoy investing like the top 1%</h3>
                        <p>
                            The top 1% investors don't stay up all night monitoring 
                            trades and assets in their portfolio, instead they get 
                            trusted wealth managers to do the hard work for them. 
                            That's exactly what you get with Xantos: an account fully 
                            managed by some of the best wealth managers in the world. 
                        </p>
                    </div>
                    <div className="feat">
                        <img src={Shield} alt="feat-img" width="100%" />
                        <h3>World-class security & compliance - of course!</h3>
                        <p>
                            With bank-grade encryption, security and compliance 
                            should be the least of your worries. We're fully licensed 
                            by the US SEC. Even better, all accounts are separately managed 
                            with up to $500,000 in SIPC insurance. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default AppFeatures;