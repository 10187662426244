import React, {useState} from 'react';
import './style.scss';
import DownloadBtn from '../../images/download.svg';
import InfoCircle from '../../images/info-circle.svg';
import Exit from '../../images/exit.svg';
import Modal from 'react-modal';


const Performance = () => {
    // modal state fxns
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true)
    }

    const setModalIsOpenToFalse =()=>{
        setModalIsOpen(false)
    }

    // modal styling   
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.22)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            //   marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            fontSize: '.95em', 
            color: '#17304E',
            textAlign: 'justify',
            width: '45%',
            padding: '2em'
        }
    }
    const customStylesMedia = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.22)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            fontSize: '.95em', 
            color: '#17304E',
            textAlign: 'justify',
            width: '70%',
            padding: '2em'
        }
    }
    const btn = {
        backgroundColor: 'unset',
        border: 'unset',
        cursor: 'pointer',
        float: 'right'
    }
    const h2 = {
        margin: '0',
        fontSize: '1.2rem',
        padding: 'unset',
    }
    const p = {
        opacity: '0.6'
    }
    return(
        <section className='Performance'>
            <div className="container">
                <h2>Don't take our word for it</h2>
                <p className="lead">
                    Here’s how our annualized returns (since inception through Sept 2022) compare to a broad market index. 
                </p>
                <div className="stats">
                    <div>
                        <h3>+10.7% p.a</h3>
                        <p>S&P 500</p>
                    </div>
                    <div>
                        <h3>+9.6% p.a</h3>
                        <p>Xantos Labs</p>
                    </div>
                </div>

                <div className="links">
                    <button onClick={setModalIsOpenToTrue}>
                        <img src={InfoCircle} alt="disclaimer" />
                        See Disclaimer
                    </button> 
        
                    <a className="factsheet" href="https://xantos-publications.s3.amazonaws.com/XL-Fact-Sheet.pdf" target="_blank" rel='noreferrer'>
                        <img src={DownloadBtn} alt="download" />
                        See Factsheet
                    </a>
                </div>

                <Modal isOpen={modalIsOpen} style={window.matchMedia("(max-width: 414px)").matches? customStylesMedia:customStyles} ariaHideApp={false}>
                    <button style={btn} onClick={setModalIsOpenToFalse}>
                        <img style={{width: '17px', filter: 'invert(1)'}} src={Exit} alt="exit-icon" />
                    </button>
                    <h2 style={h2}>Disclaimer</h2>
                    <p style={p}>
                        Investment outcomes below are real and based on actual account(s). 
                        Results are from 01/01/2016 to 09/30/2022 and for “Aggressive” investment style; 
                        clients with “Moderate” or “Conservative” investment styles would have experienced lower returns. In full disclosure of effects 
                        of material market and economic conditions on returns, S&P 500 Index returns 
                        are included. All performance results are net of fees, dividends/reinvestments, 
                        and other adjustments. Past performance does not guarantee future results. Investing 
                        carries substantial risk including loss of principal.
                    </p>
                </Modal>
            </div>
        </section>
    );
}


export default Performance;