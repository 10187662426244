import React from 'react';
import './style.scss';
import Steps from '../../images/steps1.svg';
// import StepsIcon from '../../images/steps-icon.svg';
// import StepsIcon1 from '../../images/steps-icon1.svg';
// import StepsIcon2 from '../../images/steps-icon2.svg';
import Grow from '../../images/growing.png';
import Activate from '../../images/Activate.png';
import Signup from '../../images/signup.png';


const GetStarted = () => {
    return(
        <section className="Get-Started" id='getStarted'>
            {/* <div className="shaded"> */}
                <div className="container">
                    <div className='steps-img'>
                        <h2>Get Started in 3 steps</h2>
                        <img src={Steps} alt="steps illustration" />
                    </div>
                    <div className='steps'>
                        <div className="steps-card">
                            <img src={Signup} alt="steps" width="100%" />
                            <div className="text">
                                <h3>Sign Up</h3>
                                <p>Create your free account in 3 minutes!</p>
                            </div>
                        </div>
                        <div className="steps-card">
                            <img className='activate' src={Activate} alt="steps" width="100%" />
                            <div className="text">
                                <h3>Activate your account</h3>
                                <p>Fund your account almost instantly and start enjoying the best investing experience ever!</p>
                            </div>
                        </div>
                        <div className="steps-card">
                            <img src={Grow} alt="steps" width="100%" />
                            <div className="text">
                                <h3>Watch Your money grow</h3>
                                <p>Sit back and relax while we grow and manage your money for you </p>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </section>
    );
}


export default GetStarted;