import React from 'react';
import XantosLogo from '../../images/xantos-labs-logo.svg'
import Menu from '../../images/menu.svg'
import Exit from '../../images/exit.svg'

const GetAppFunc = () => {
    let link;
    if(navigator.userAgent.match(/iPhone/i)){
        link =  "https://apps.apple.com/us/app/xantos-labs/id1571651655"
    }else if(navigator.userAgent.match(/iPad/i)){
        link =  "https://apps.apple.com/us/app/xantos-labs/id1571651655"
    }else if(navigator.userAgent.match(/Android/i)){
        link = "https://play.google.com/store/apps/details?id=com.xantoslabs" 
    }

    return link
}
const Nav = () => {
    // useEffect(() => {
    //     GetAppFunc();
    // }, []);


    const links = GetAppFunc()
    return(
        <div className='Navbar'>
            <div className='container'>
                <a className='Logo' href='index.html'>
                    <img src={XantosLogo} alt='xantos-labs-logo' />
                </a>

                <nav>
                    <img id='mobile-exit' className='mobile-menu-exit' src={Exit} alt='exit' />
                    <ul className='primary-nav'>
                        <li className='current'><a href='#home'>Home</a></li>
                        <li><a href='#features'>Features</a></li>
                        <li><a href='#getStarted'>Get Started</a></li>
                        <li><a href='https://xantoslabs.com/faq'>FAQs</a></li>
                    </ul>
                </nav>
                
                <a className='GetApp' id='get-the-app' href={links} target="_blank" rel="noreferrer">
                    Get Xantos App
                </a>
                
                <img id='mobile-cta' className='mobile-menu' src={Menu} alt='open navigation' />
            </div>
        </div>
    );
}

export default Nav;